@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: offer-category-card;

@import 'offer-category-card.vars';

.offer-category-card {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 16px 8px;
  border-radius: 12px;
  background-color: rgba($black, .05);
  transition: border-color .1s, background-color .1s, box-shadow .1s;

  @media (min-width: $tablet-portrait) {
    min-height: 134px;
  }

  &:hover,
  &:focus-within {
    box-shadow: inset 0 0 0 1px rgba($black, .05), 0 5px $box-shadow-size 0 rgba($black, .05);
    background-color: $background-color--body;
  }
}
