$b: category-icon;

// stylelint-disable plugin/selector-bem-pattern

@use 'sass:list';
@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';

@import '../category-icon.vars.scss';

@each $name, $values in $colors {
  $from: list.nth($values, 1);
  $to: list.nth($values, 2);
  $shadow: list.nth($values, 3);

  .category-icon_color_#{$name} {
    box-shadow: 0 4px 9px 0 $shadow;
    background: linear-gradient(180deg, $from .8%, $to 100%);
  }
}
