@use 'sass:map';

$colors: (
  'purple': (#d625c6, #93118d, #7a0f8c59), // по-умолчанию
  'navy': (#11fef0, #08dbce, #09dcd059),
  'green': (#15cf74, #06ae5c, #07b05d59),
  'yellow': (#face64, #fdb300, #fcb40759),
  'orange': (#ff9175, #e85304, #e8530459),
  'black': (#5a5c6a, #0d0d0f, #4a537659),
  'blue': (#45b7fd, #198cd1, #11a9fa59),
  'tropical': (#9bd96b, #76c03d, #76c03d59),
  'nepal': (#afbede, #8c9bba, #8c9bba59),
  'brick': (#f67292, #ae2c4c, #ae2c4c59),
  'berry': (#d298e2, #b96ece, #b96ece59),
  'light-tomato': (#f1a79d, #ce796e, #b96ece59)
);

$symbol-names: (
  'default', // по-умолчанию
  'alcohol-counting',
  'balance',
  'bonus',
  'car',
  'cards',
  'cloud',
  'crown',
  'day',
  'delivery',
  'football',
  'games',
  'headphones',
  'health',
  'heart',
  'internet-access',
  'kids',
  'lightning',
  'like',
  'mail',
  'message',
  'movies',
  'museum',
  'music',
  'night',
  'personal-offer',
  'phone',
  'plane',
  'question',
  'railway',
  'roaming',
  'rocket',
  'safety',
  'shop',
  'shopping',
  'sim-card',
  'snowflake',
  'sos',
  'spotlight',
  'subscriptions',
  'survey',
  'target',
  'tv',
  'vip',
  'star',
  'book',
  'diamond',
  'bulb',
);

@function make-symbols-list() {
  $list: ();

  @each $name in $symbol-names {
    $list: map.set($list, $name, url('./category-icon.assets/#{$name}.svg?resource'));
  }

  @return $list;
}

$symbols: make-symbols-list();
