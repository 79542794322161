@import 'node_modules/@funboxteam/scss-utils/placeholder';
@import 'node_modules/@funboxteam/scss-utils/visually-hidden';
@import 'node_modules/@funboxteam/scss-utils/text-cut';
@import 'node_modules/@funboxteam/scss-utils/multiline-text-cut';
@import 'node_modules/@kvantera/megafon-brand-elements/breakpoints/breakpoints.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/colors/colors.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/typography/typography.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/layout/layout.vars';
@import 'node_modules/@kvantera/megafon-brand-elements/heights/heights.vars';
$b: category-icon;

.category-icon {
  display: inline-block;
  border-radius: 28px;

  &::before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    background-position: center;
    background-size: 36px;
    background-repeat: no-repeat;

    @media (min-width: $tablet-portrait) {
      width: 56px;
      height: 56px;
      background-size: 40px;
    }
  }
}
